import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'components/Forms/FormComponents/Select';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addNotification } from 'state/notificationSlice';
import { randomHash } from 'utils/string';
import { Product } from 'utils/types';

export default function PromotionCopyModal(props: {
	modalOpened: boolean;
	setModalOpened: (opened: boolean) => void;
	i_business: number;
	product: Product;
	action: 'COPY' | 'REPLACE';
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [selectedList, setSelectedList] = useState('');
	const [availableLists, setAvailableLists] = useState([] as { i_list: number; c_list: string }[]);

	const listOptions = useMemo(() => {
		const res = new Map<string, string>();

		res.set('', t('promotion.fields.create_new_promotion'));
		availableLists.forEach((list) => {
			res.set(list.i_list.toString(), `${list.i_list} - ${list.c_list}`);
		});

		return res;
	}, [availableLists]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const i_list = parseInt(urlParams.get('i_list') ?? '1');
		sendHttpRequest(
			'GET',
			`/promotion/${i_list}/available/${props.i_business}`,
			null,
			navigate,
			dispatch
		).then((response) => setAvailableLists(response.data[0]));
	}, [dispatch, navigate, props.i_business]);

	async function copyOrReplaceProduct() {
		const urlParams = new URLSearchParams(window.location.search);
		const response = await sendHttpRequest(
			'POST',
			'/promotion/copy',
			{
				i_list: parseInt(urlParams.get('i_list') ?? '1'),
				i_product: props.product.i_product,
				i_template: props.product.i_template,
				i_list_target: selectedList ? parseInt(selectedList) : null,
				action: props.action
			},
			navigate,
			dispatch
		);
		const i_list = response.data.find((d: any[]) => d[0].i_list_target !== undefined)[0]
			.i_list_target;
		dispatch(
			addNotification({
				id: randomHash(),
				message:
					props.action === 'COPY'
						? t('promotion.notifications.product_copied_successfully', { i_list })
						: t('promotion.notifications.product_replaced_successfully', { i_list }),
				dismissable: true,
				severity: 'success',
				navigation: '/list_detail?i_list=' + i_list,
				actionText: t('promotion.notifications.open')
			})
		);
	}

	return (
		<>
			<Modal show={props.modalOpened} onHide={() => props.setModalOpened(false)}>
				<Modal.Header closeButton>
					<h2 className="fs-5 m-0">
						{props.action === 'COPY'
							? t('general.phrases.copy_product')
							: t('general.phrases.replace_product')}
					</h2>
				</Modal.Header>

				<Modal.Body>
					{t('general.phrases.promotion_product_copy_phrase')}
					<Select
						required={true}
						customClass="mt-3"
						name={'sourceLanguage'}
						label={t('general.phrases.promotion')}
						options={listOptions}
						defaultValue={selectedList}
						onChange={(event) => setSelectedList(event.target.value)}
						disabled={loading}
					/>

					<button
						type="button"
						className="btn btn-primary mt-3 col-12 btn-lg"
						onClick={() => {
							setLoading(true);
							copyOrReplaceProduct().then(() => {
								setLoading(false);
								props.setModalOpened(false);
							});
						}}
						disabled={loading}>
						{!loading ? (
							props.action === 'COPY' ? (
								t('general.phrases.copy_product')
							) : (
								t('general.phrases.replace_product')
							)
						) : (
							<span
								className="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"></span>
						)}
					</button>
				</Modal.Body>
			</Modal>
		</>
	);
}
